import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"

import { breakpoints } from "../parameters/theme"

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 4em;
  max-width: ${breakpoints.tablet}px;
  padding: 1em 1em 0;
  overflow-wrap: break-word;

  & > p:first-of-type {
    font-size: inherit !important;
  }

  &::after {
    clear: both;
    content: ".";
    display: block;
    height: 0;
    visibility: hidden;
  }

  & > *:not(:first-child) {
    margin-top: 1em;
  }

  & > a {
    display: block;
  }

  @media (max-width: ${breakpoints.tablet}px) {
    margin-top: 0;
  }
`

const NotFoundPage = ({
  data: { allContentfulArticle: allArticles },
  location,
}) => {
  const [article, setArticle] = useState(null)
  const articlesCopied = [...allArticles.edges]

  useEffect(() => {
    const randomInd = Math.floor(
      Math.random() * Math.floor(articlesCopied.length)
    )

    setArticle(articlesCopied[randomInd].node)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout
      location={location}
      pageType="page"
      pageClassName="page"
      pageContext={{ notFound: true }}
      title="Ooops!"
      nodeData={{}}
    >
      <Container>
        <p>
          Sorry but we can't find the page you're looking for. Bummer! Perhaps
          there's a typo in the address? Or maybe we simply lost the page.
          Either way, there's unfortunately nothing here. :( But there are other
          stuff you might like! Here are two options:
        </p>
        <ul>
          <li>
            Head back{" "}
            <Link to="/" title="Homepage">
              HOME
            </Link>{" "}
            and look for something else.
          </li>
          <li>
            Check out a{" "}
            {article ? (
              <Link to={`/${article.slug}`} title={article.title}>
                random article
              </Link>
            ) : null}
            .
          </li>
        </ul>
      </Container>
    </Layout>
  )
}

export const NotFoundQuery = graphql`
  query {
    allContentfulArticle {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`

export default NotFoundPage
